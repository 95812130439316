import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProjectsGridService } from '../projects-grid/projects-grid.service';
import { helperService } from 'src/app/utils/helper';
import { ClientTimeReportService } from './client-time-report.service';
import { Table } from 'primeng/table';
import { timeRecord } from 'src/app/utils/filterDropDownData';

@Component({
  selector: 'appclient-time-report',
  templateUrl: './client-time-report.component.html',
  styleUrls: ['./client-time-report.component.scss']
})
export class ClientTimeReportComponent implements OnInit {

  reportNonSummaryList: any[] = [];
  includeDetailsList: any[] = [];
  includeDetailsCols: any[] = [];
  reportSummaryList: any[] = [];
  selectedUsers: any[] = [];
  allUsers: any[] = [];
  allOrganisations: any[] = [];
  selectedOrgs: any[] = [];
  isSummaryOnly: Boolean = false;
  isIncludeDetails: Boolean = false;
  generateLoader: Boolean = false;
  showIncludeDetails: Boolean = false;
  showSummaryOnly: Boolean = false;
  selectedDates: any;
  submitted: Boolean = false;
  defaultDate: Date = new Date();
  exportColumns: any[] = []
  nonSummaryCols: any[] = []
  summaryCols: any[] = []
  title: any = "";
  allMatters: any[] = [];
  billableList: any[] = [];
  selectedMatters: any[] = [];
  isLoading: Boolean = false;
  constructor(private projectService: ProjectsGridService, private helper: helperService, private clientService: ClientTimeReportService) { }


  ngOnInit(): void {
    this.isLoading=true;
    this.summaryCols = [
      { field: 'orgName', header: 'Organization Name' },
      { field: 'totaltime', header: 'Total Time (HH:MM)' },
      { field: 'totaltimeDecimal', header: 'Total Time (Decimals)' },
    ];
    this.nonSummaryCols = [
      { field: 'orgName', header: 'Organization Name' },
      { field: 'matterName', header: 'Matter Name' },
      { field: 'projName', header: 'Project Name' },
      { field: 'userName', header: 'User Name' },
      { field: 'loggedTime', header: 'Total Project Time (HH:MM)' },
      { field: 'loggedTimeDecimal', header: 'Total Project Time (Decimals)' },
      { field: 'totaltime', header: 'Total User Time (HH:MM)' },
      { field: 'totaltimeDecimal', header: 'Total User Time (Decimals)' }
    ];
    this.includeDetailsCols = [
      { field: 'orgName', header: 'Organization Name' },
      { field: 'matterName', header: 'Matter Name' },
      { field: 'projName', header: 'Project Name' },
      { field: 'projTaskName', header: 'Task Name' },
      { field: 'trDateStr', header: 'Time Record Date' },
      { field: 'trDescription', header: 'Time Record Description' },
      { field: 'userName', header: 'User Name' },
      { field: 'projTaskIsBillable', header: 'Time Record Billable' },
      { field: 'totalOrgTime', header: 'Total Organization Time (HH:MM)' },
      { field: 'totalOrgTimeDecimal', header: 'Total Organization Time (Decimals)' },
      { field: 'totalProjTime', header: 'Total Project Time (HH:MM)' },
      { field: 'totalProjTimeDecimal', header: 'Total Project Time (Decimals)' },
      { field: 'totalTaskTime', header: 'Total Task Time (HH:MM)' },
      { field: 'totalTaskTimeDecimal', header: 'Total Task Time (Decimals)' },
      { field: 'loggedTrTime', header: 'Total Time Record Time (HH:MM)' },
      { field: 'loggedTrTimeDecimal', header: 'Total Time Record Time (Decimals)' },
    ];

    // this.exportColumns = this.summaryCols.map(col => ({title: col.header, dataKey: col.field}));

    this.projectService.getAllOrganizations().subscribe(res => {
      this.allOrganisations = res.data;
      this.selectedOrgs = res.data.map((obj: any) => obj.id);
      this.clientService.getMattersByOrgId({ "orgIds" : [] }).subscribe(res => {
        this.allMatters = res.data;
        this.selectedMatters = res.data.map((obj: any) => obj.matterId);
        this.isLoading=false;
      })
    })
    


    this.projectService.getLPCUsersList({
      "roles": ["TK"],
      // "userTypes": ["Internal"]
    }).subscribe(res => {
      this.allUsers = res.data;
      this.selectedUsers = res.data.map((obj: any) => obj.id);
    })

    this.billableList=timeRecord.billableList;

  }

  fetchMattersByOrgIds(){
    this.isLoading=true;
    if(this.selectedOrgs && this.selectedOrgs.length > 0){
      this.clientService.getMattersByOrgId({ "orgIds" : this.selectedOrgs }).subscribe(res => {
        this.allMatters = res.data;
        this.selectedMatters = res.data.map((obj: any) => obj.matterId);
        this.isLoading=false;
      })
    }else{
      this.allMatters = []
      this.selectedMatters = []
      this.isLoading=false;
    }
    
  }



  loadClientReportData() {
    this.submitted = true;
    if ((!this.selectedDates || this.selectedDates.length == 0) || (!this.selectedOrgs || this.selectedOrgs.length == 0) || (!this.selectedUsers || this.selectedUsers.length == 0)) {
      return
    }
    this.reportSummaryList = []
    this.reportNonSummaryList = []
    this.includeDetailsList=[]
    let payload = {
      startDate: this.helper.convertDateToFormattedStr(this.selectedDates[0]),
      endDate: this.helper.convertDateToFormattedStr(this.selectedDates[1]),
      orgIds: this.selectedOrgs.length == this.allOrganisations.length ? [] : this.selectedOrgs,
      userIds: this.selectedUsers.length == this.allUsers.length ? [] : this.selectedUsers,
      summaryOnly: this.isSummaryOnly,
      includeDetail: this.isIncludeDetails,
      matterIds: this.selectedMatters
    }
    console.log(payload)
    this.generateLoader = true;
    this.isLoading = true;
    
    this.clientService.getClientReports(payload).subscribe(res => {
      let userList = []
      if (res && res.data && this.isIncludeDetails) {
        userList = this.flattenDetailsData(res.data);
        if (userList.length > 0) {
          this.includeDetailsList = userList
          this.showSummaryOnly = false;
          this.showIncludeDetails = true;
        }
      }
      else if (res && res.data) {
        let list = res.data
        for (let rec of list) {
          if (rec.data) {
            for (let item of rec.data) {
              let obj: any = {}
              obj = { ...rec, ...item }
              delete obj.data;
              obj.totaltimeDecimal = this.helper.convertTimeNumToDecimal(obj.totaltime);
              obj.totaltime = this.helper.convertTimeNumToStr(obj.totaltime);
              obj.loggedTimeDecimal = this.helper.convertTimeNumToDecimal(obj.loggedTime);
              obj.loggedTime = this.helper.convertTimeNumToStr(obj.loggedTime);
              obj.trDate = new Date(obj.trDate);
              userList.push(obj)
            }
          } else {
            rec.totaltimeDecimal = this.helper.convertTimeNumToDecimal(rec.totaltime);
            rec.totaltime = this.helper.convertTimeNumToStr(rec.totaltime);
          }
        }
        if (userList.length > 0) {
          this.reportNonSummaryList = userList
          this.showSummaryOnly = false;
        } else {
          this.reportSummaryList = list
          this.showSummaryOnly = true;
        }
        this.showIncludeDetails = false;
      }
      this.generateLoader = false;
      this.isLoading = false;
      this.submitted = false;
    }, error => {
      this.generateLoader = false;
      this.isLoading = false;
      this.submitted = false;
    });
  }

  exportReport(table: Table,exportFilename:any) {
    table.exportFilename =exportFilename;
    table.exportCSV()
    // if (this.showSummaryOnly) {
    //   table.exportFilename = 
    // } else {
    //   table.exportFilename = "Client Detailed Time Report"
    //   table.exportCSV()
    // }
  }



  flattenDetailsData(data: any[]): any[] {
    let flatArray: any[] = [];

    data.forEach(org => {
      org.data.forEach((proj: any) => {
        proj.projTasks.forEach((task: any) => {
          task.timeRecords.forEach((record: any) => {
            flatArray.push({
              orgId: org.orgId,
              orgName: org.orgName,
              matterName: proj.matterName,
              projName: proj.projName,
              userId: proj.userId,
              userName: proj.userName,
              projTaskId: task.projTaskId,
              projTaskName: task.projTaskName,
              projTaskIsBillable: task.projTaskIsBillable,
              projTaskFullName: task.projTaskFullName,
              taskCreatedOn: task.taskCreatedOn,
              trId: record.trId,
              trDescription: record.trDescription,
              trDate: new Date(record.trDate),
              trDateStr: record.trDate,
              loggedTrTime: this.helper.convertTimeNumToStr(record.loggedTime),
              loggedTrTimeDecimal: this.helper.convertTimeNumToDecimal(record.loggedTime),
              totalTaskTime: this.helper.convertTimeNumToStr(task.loggedTime),
              totalTaskTimeDecimal: this.helper.convertTimeNumToDecimal(task.loggedTime),
              totalProjTime: this.helper.convertTimeNumToStr(proj.loggedTime),
              totalProjTimeDecimal: this.helper.convertTimeNumToDecimal(proj.loggedTime),
              totalOrgTime: this.helper.convertTimeNumToStr(org.totaltime),
              totalOrgTimeDecimal: this.helper.convertTimeNumToDecimal(org.totaltime)
            });
          });
        });
      });
    });
    console.log(flatArray)
    return flatArray;
  }

}
