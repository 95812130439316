import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AuthGuard } from 'src/app/account/auth/app.gaurd';
import { ProjectsGridService } from 'src/app/pages/projects-grid/projects-grid.service';
import { UsersGridService } from 'src/app/pages/users-grid/users-grid.service';
import { task } from 'src/app/utils/filterDropDownData';
import { helperService } from 'src/app/utils/helper';
import {  userPmPracticeAreas, userRoles, userTimeZones, userTkPracticeAreas, userTypes } from 'src/app/utils/userData';


@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {

  @Input() profile?: boolean;
  @Input() viewOnly?: boolean;
  @Input() newUser?: boolean;
  @Input() user?: any;
  @Input() submitted?: any;
  @Input() userDialog?: any;
  @Output() closeDialogonChange: EventEmitter<any> = new EventEmitter();

  userObj: any = {};
  viewParam: boolean = false;
  userForm: any = {}
  roles: any = [];
  types: any = [];
  tkAreas: any = [];
  pmAreas: any = [];
  timeZones: any = [];
  stateCodeMapList: any = [];
  isLoading: boolean = false;
  errorMsg: any = {};
  mobNoValid: boolean = false;
  phNoValid: boolean = false;
  lpcUsersList:any=[];
  isAdmin: boolean = false;
  private phonePattern = /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})\D*$/;

  constructor(private helperService: helperService,private userService: UsersGridService, private readonly messageService: MessageService,private projectService:ProjectsGridService, private authService: AuthGuard) { }

  ngOnInit(): void {

    this.stateCodeMapList = task.stateCodeMapList;

    this.types = userTypes;

    this.getTkPracticeArea();
    
    if(this.helperService.isAdminUser(this.authService.roles)){
      this.isAdmin=true
    }

    this.projectService.getLPCUsersList({
      "roles": ["LPC", "ADMIN", "Approver"],
      // "userTypes": ["Internal"]
    }).subscribe((res:any) => {
      this.lpcUsersList = res.data;
    })

    this.pmAreas = userPmPracticeAreas;

    this.timeZones = userTimeZones;

    this.roles = userRoles;

    this.userForm = new FormGroup({
      id: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      prefix: new FormControl(''),
      middleName: new FormControl(''),
      lastName: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      companyName: new FormControl(''),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      mainEmail: new FormControl('', [Validators.required, Validators.email]),
      otherEmail: new FormControl('', [Validators.email]),
      extnNo: new FormControl(''),
      mobileNumber: new FormControl(''),
      phoneNumber: new FormControl('', [Validators.required]),
      faxNumber: new FormControl(''),
      finCenId: new FormControl(''),
      qbUserId: new FormControl(''),
      timeZoneCode: new FormControl('', [Validators.required]),
      roleCodes: new FormControl('', [Validators.required]),
      tkPracticeArea: new FormControl([], [Validators.required]),
      pmPracticeArea: new FormControl(''),
      userTypeCode: new FormControl(''),
      approver:new FormControl(''),
      tkAutoApproval:new FormControl('')
    });
    this.user.roleCodes = this.user.roleCodes.filter((value: any, index: any) => this.user.roleCodes.indexOf(value) === index);
    this.userForm.patchValue(this.user);
    this.formatPhoneNumber(this.user.phoneNumber, false);
    this.formatPhoneNumber(this.user.mobileNumber, true);
    if (this.viewOnly) {
      this.userForm.disable();
    }
    if (this.profile) {
      this.userForm.disable();
      this.userForm.controls['otherEmail'].enable();
      this.userForm.controls['address'].enable();
      this.userForm.controls['city'].enable();
      this.userForm.controls['zip'].enable();
      this.userForm.controls['state'].enable();
      this.userForm.controls['timeZoneCode'].enable();
      this.userForm.controls['mobileNumber'].enable();
      this.userForm.controls['extnNo'].enable();
      this.userForm.controls['finCenId'].enable();
      this.userForm.controls['approver'].enable();
      this.userForm.controls['phoneNumber'].enable();
    }
   
  }

  rolecheck(userForm: any) {
    let roles = userForm.roleCodes;
    if (roles.includes("Approver")) {
      if (roles.length > 1) {
        return true;
      }
      return false;
    }
    return true;
  }

  getTkPracticeArea(){
    this.userService.getPracticeArea().subscribe(res=>{
        this.tkAreas=res.data;
    })
    // this.tkAreas=userTkPracticeAreas;
  }



  // Format the phone number
  formatPhoneNumber(value: string, mobileNumber: boolean) {
    value = this.deformatPhoneNumber(value);
    if (value) {
      const match = value.match(this.phonePattern);
      let x = value;
      if (match) {
        const groups = match.slice(1, 4);
        const filteredGroups = groups.filter(group => group !== undefined);
        x = filteredGroups.length > 0 ? `(${filteredGroups[0]})${filteredGroups[1] ? ` ${filteredGroups[1]}` : ''}${filteredGroups[2] ? `-${filteredGroups[2]}` : ''}` : '';
      }
      if (mobileNumber) {
        this.userForm.controls.mobileNumber.patchValue(x);
      }
      else {
        this.userForm.controls.phoneNumber.patchValue(x);
      }
    }
    else {
      if (mobileNumber) {
        this.userForm.controls.mobileNumber.patchValue('');
      }
      else {
        this.userForm.controls.phoneNumber.patchValue('');
      }
    }

  }

  // Deformat the phone number
  deformatPhoneNumber(value: string): string {
    if (value) {
      const match = value.match(/\d/g);

      if (match) {
        return match.join('');
      }
    }
    return '';
  }

  saveUser() {
    this.messageService.clear();
    if (this.isLoading)
      return;
    this.isLoading = true;
    this.submitted = true;
    if (this.userForm.valid && this.rolecheck(this.userForm.getRawValue()) && this.validateNumbers(this.userForm.getRawValue())) {
      this.userForm.disable();
      this.userService.addUser(this.userForm.getRawValue()).subscribe(res => {
        this.isLoading = false;
        if (res.success) {
          this.viewOnly = true;
          this.messageService.add({ severity: 'success', detail: 'User ' + this.userForm.controls['firstName'].value + ' details have been saved successfully' });
        } else {
          //this.userForm.enable();
          if (!this.newUser) {
            this.userForm.controls['mainEmail'].disable();
            // this.userForm.controls['mobileNumber'].disable();
          }
          this.messageService.add({ severity: 'error', detail: res.message });
        }
      }, error => {
        this.isLoading = false;
        //this.userForm.enable();
        this.messageService.add({ severity: 'error', detail: error.message });
      })
    }
    else if (this.rolecheck(this.userForm.getRawValue())) {
      this.messageService.add({ severity: 'error', detail: "Approver role must be allocated with atleast one other role." });
    }
    else {
      this.messageService.add({ severity: 'error', detail: "Please check for all the mandatory fields." });
    }


  }
  editUser() {
    this.messageService.clear();
    if (this.isLoading)
      return;
    this.submitted = true;
    if (this.userForm.valid && this.rolecheck(this.userForm.getRawValue()) && this.validateNumbers(this.userForm.getRawValue())) {
      if(this.helperService.isOnlyTKUser(this.userForm.getRawValue().roleCodes) 
        && this.userForm.getRawValue().roleCodes.includes('Approver') 
        && this.userForm.getRawValue().roleCodes.length <=2){
        this.messageService.add({ severity: 'error', detail: 'Unable to assign Approver role: user currently only has TK role' });
        return;
      }
      this.userForm.disable();
      this.isLoading = true;
      this.userService.editUser(this.userForm.getRawValue()).subscribe(res => {
        this.isLoading = false;
        if (res.success) {
          this.viewOnly = true;
          this.messageService.add({ severity: 'success', detail: 'User ' + this.userForm.controls['firstName'].value + ' details have been saved successfully' });
        } else {
          //this.userForm.enable();
          this.messageService.add({ severity: 'error', detail: res.message });
        }
      }, error => {
        this.isLoading = false;
        //this.userForm.enable();
        this.messageService.add({ severity: 'error', detail: error.message });
      })
    }
    else {
      this.messageService.add({ severity: 'error', detail: "Please check for all the mandatory fields."+(!this.rolecheck(this.userForm.getRawValue())?"And also an approver role must be allocated with atleast one other role.":'' )});
    }
  }

  validateNumbers(user: any) {
    const phvalue = user.phoneNumber;
    // const movalue = user.mobileNumber;

    // Check if the value is numeric and has 10 digits
    // const isMValid = /^\(\d{3}\) \d{3}-\d{4}$/.test(movalue);
    const isPValid = /^\(\d{3}\) \d{3}-\d{4}$/.test(phvalue);
    // this.mobNoValid=isMValid;
    this.phNoValid = isPValid;
    if (isPValid) {
      return true;
    }
    else { return false; }
  }
  closeDialog() {
    this.closeDialogonChange.emit("");
    this.userForm.disable();
    this.userDialog = false;
  }

  get firstName() {
    return this.userForm.get('firstName');
  }
  get middleName() {
    return this.userForm.get('middleName');
  }
  get lastName() {
    return this.userForm.get('lastName');
  }
  get prefix() {
    return this.userForm.get('prefix');
  }
  get city() {
    return this.userForm.get('city');
  }
  get state() {
    return this.userForm.get('state');
  }
  get mainEmail() {
    return this.userForm.get('mainEmail');
  }
  get extnNo() {
    return this.userForm.get('extnNo');
  }
  get zip() {
    return this.userForm.get('zip');
  }
  get address() {
    return this.userForm.get('address');
  }
  get mobileNumber() {
    return this.userForm.get('mobileNumber');
  }
  get phoneNumber() {
    return this.userForm.get('phoneNumber');
  }
  get faxNumber() {
    return this.userForm.get('faxNumber');
  }
  get finCenId() {
    return this.userForm.get('finCenId');
  }
  get timeZoneCode() {
    return this.userForm.get('timeZoneCode');
  }
  get roleCodes() {
    return this.userForm.get('roleCodes');
  }
  get tkPracticeArea() {
    return this.userForm.get('tkPracticeArea');
  }
  get pmPracticeArea() {
    return this.userForm.get('pmPracticeArea');
  }
  get userTypeCode() {
    return this.userForm.get('userTypeCode');
  }
  get approver() {
    return this.userForm.get('approver');
  }
  get tkAutoApproval() {
    return this.userForm.get('tkAutoApproval');
  }

}
